import FileSaver from 'file-saver';
import { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../App';
import Loader from '../../components/shared/loader/Loader';
import { viewImageInFrame, unlockAlbum } from '../../store/actions/user';
import getFileByUrl from '../../utils/getFileByUrl';
import { useSwipeable } from 'react-swipeable';
import {
  Button,
  Cross,
  CrossWrapper,
  Download,
  Footer,
  FooterButton,
  Img,
  LeftWrapper,
  Share,
  SliderNav,
  SliderNavPrev,
  SliderNavNext,
  Word,
  Wrapper,
  LoaderWrapper,
} from './LandscapeImageStyles';

interface Props {
  image?: any;
  imageId: string;
  isUnlocked?: boolean;
  albumId: string;
  onPrev: () => void;
  onNext: () => void;
  onClose: () => void;
}

const LandscapeImage = ({
  image,
  imageId,
  isUnlocked,
  albumId,
  onPrev,
  onNext,
  onClose,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setLoading] = useState(false);
  const [imgIsLoading, setImgLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const shareFrame = useRef<HTMLIFrameElement>(null!);
  const FRAMEOLOGY_VIEW_IN_FRAME_URL =
    process.env.REACT_APP_FRAMEOLOGY_HANDOFF_URL || 'https://frameology.com/collections/all';

  const shareFile = async () => {
    const file = await getFileByUrl(image);
    try {
      await shareFrame.current?.contentWindow?.navigator.share({
        files: [file],
        title: '',
      });
    } catch {}
    shareFrame.current?.contentWindow?.location.reload();
  };

  const onButtonClick = async () => {
    FileSaver.saveAs(image, imageId);
  };

  const handleShareButton = async () => {
    // Check if navigator.share is supported by the browser
    if (typeof navigator.share === 'function') {
      await shareFile();
    } else {
      copyToClipboard();
    }
  };

  const onSeeClick = async () => {
    setIsFetching(true);
    const handoffUrl = `${FRAMEOLOGY_VIEW_IN_FRAME_URL}?utm_source=photodrop&utm_medium=handoff&utm_campaign=${albumId}&utm_content=${imageId}`;
    console.log(`Handing off to Frameology URL: ${handoffUrl}`);
    const newWindow = window.open(handoffUrl);
    const file = await getFileByUrl(image);
    await dispatch(viewImageInFrame(file, newWindow));
    setIsFetching(false);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => onNext(),
    onSwipedRight: () => onPrev(),
    swipeDuration: 800,
    delta: 10,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const copyMessage = () => {
    return encodeURIComponent(image);
  };

  const copyToClipboard = async () => {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(decodeURIComponent(copyMessage()));
      alert('Photo link was copied to your clipboard');
    }
  };

  const onUnlockClick = async () => {
    setLoading(true);
    await dispatch(unlockAlbum(albumId as string));
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      switch (event.keyCode) {
        case 27:
          onClose();
          break;
        case 37:
          onPrev();
          break;
        case 39:
          onNext();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Wrapper>
      <iframe
        allow="web-share"
        title="share frame"
        sandbox="allow-scripts allow-same-origin"
        ref={shareFrame}
        style={{ display: 'none' }}
      />
      <CrossWrapper>
        <Cross onClick={() => onClose()} src="/assets/icons/cross.svg" alt="" />
      </CrossWrapper>
      <SliderNav>
        <SliderNavPrev onClick={onPrev}>Prev</SliderNavPrev>
        <SliderNavNext onClick={onNext}>Next</SliderNavNext>
      </SliderNav>
      {imgIsLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <Img
        src={image}
        alt=""
        {...handlers}
        draggable="false"
        loading="eager"
        onLoad={() => {
          setImgLoading(false);
        }}
      />
      {!isUnlocked ? (
        <Button onClick={onUnlockClick}>{isLoading ? <Loader /> : 'Unlock photo'}</Button>
      ) : (
        <Footer>
          <LeftWrapper>
            <Download onClick={onButtonClick}>
              <img width="24px" height="21px" src="/assets/icons/download.svg" alt="" />
              <Word>Download</Word>
            </Download>

            <Share onClick={handleShareButton}>
              <img width="24px" height="21px" src="/assets/icons/share.svg" alt="" />
              <Word>Share</Word>
            </Share>
          </LeftWrapper>
          <div>
            <FooterButton onClick={onSeeClick}>
              {isFetching ? <Loader /> : 'See in a frame'}
            </FooterButton>
          </div>
        </Footer>
      )}
    </Wrapper>
  );
};

export default LandscapeImage;
